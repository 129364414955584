exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-awards-teacher-of-the-year-nominate-tsx": () => import("./../../../src/pages/awards/teacher-of-the-year/nominate.tsx" /* webpackChunkName: "component---src-pages-awards-teacher-of-the-year-nominate-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-five-plus-registration-tsx": () => import("./../../../src/pages/five-plus-registration.tsx" /* webpackChunkName: "component---src-pages-five-plus-registration-tsx" */),
  "component---src-pages-institutions-tsx": () => import("./../../../src/pages/institutions.tsx" /* webpackChunkName: "component---src-pages-institutions-tsx" */),
  "component---src-pages-new-member-institution-application-tsx": () => import("./../../../src/pages/new-member-institution-application.tsx" /* webpackChunkName: "component---src-pages-new-member-institution-application-tsx" */),
  "component---src-pages-people-tsx": () => import("./../../../src/pages/people.tsx" /* webpackChunkName: "component---src-pages-people-tsx" */),
  "component---src-pages-report-card-institutions-texas-a-tsx": () => import("./../../../src/pages/report-card/institutions/Texas+A.tsx" /* webpackChunkName: "component---src-pages-report-card-institutions-texas-a-tsx" */),
  "component---src-pages-sanity-event-slug-current-tsx": () => import("./../../../src/pages/{SanityEvent.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-event-slug-current-tsx" */),
  "component---src-pages-sanity-grid-page-slug-current-tsx": () => import("./../../../src/pages/{SanityGridPage.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-grid-page-slug-current-tsx" */),
  "component---src-pages-sanity-person-slug-current-tsx": () => import("./../../../src/pages/{SanityPerson.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-person-slug-current-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-awardee-page-template-tsx": () => import("./../../../src/templates/AwardeePageTemplate.tsx" /* webpackChunkName: "component---src-templates-awardee-page-template-tsx" */),
  "component---src-templates-events-page-template-tsx": () => import("./../../../src/templates/EventsPageTemplate.tsx" /* webpackChunkName: "component---src-templates-events-page-template-tsx" */),
  "component---src-templates-fellows-list-page-template-tsx": () => import("./../../../src/templates/FellowsListPageTemplate.tsx" /* webpackChunkName: "component---src-templates-fellows-list-page-template-tsx" */),
  "component---src-templates-five-plus-club-awardees-page-template-tsx": () => import("./../../../src/templates/FivePlusClubAwardeesPageTemplate.tsx" /* webpackChunkName: "component---src-templates-five-plus-club-awardees-page-template-tsx" */),
  "component---src-templates-institution-page-template-tsx": () => import("./../../../src/templates/InstitutionPageTemplate.tsx" /* webpackChunkName: "component---src-templates-institution-page-template-tsx" */),
  "component---src-templates-nrc-page-template-tsx": () => import("./../../../src/templates/NRCPageTemplate.tsx" /* webpackChunkName: "component---src-templates-nrc-page-template-tsx" */),
  "component---src-templates-sanity-page-template-tsx": () => import("./../../../src/templates/SanityPageTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-page-template-tsx" */),
  "component---src-templates-state-page-template-tsx": () => import("./../../../src/templates/StatePageTemplate.tsx" /* webpackChunkName: "component---src-templates-state-page-template-tsx" */),
  "component---src-templates-toty-list-page-template-tsx": () => import("./../../../src/templates/TOTYListPageTemplate.tsx" /* webpackChunkName: "component---src-templates-toty-list-page-template-tsx" */)
}

